import React from 'react'
import Link from 'gatsby-link'
import ProfileHeader from '../components/ProfileHeader'
import FollowForm from '../components/FollowForm'

const EmailPage = () => (
  <div>
    <h3> Subscribe to my weekly newsletter </h3>
    <FollowForm />
  </div>
)

export default EmailPage
